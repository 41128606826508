<template>
    <div class="main_box">
        <div class="head_title">
            合同信息表
        </div>
        <div class="form_box">
            <el-form
                ref="form"
                :rules="rules"
                :model="form"
                label-width="150px"
            >
                <div class="form_item_box">
                    <el-form-item class="w47" label="合同编号：">
                        <el-input :disabled="true" :class="`${form.check ? 'p_l_inp' : ''}`" v-model="form.contract_number">
                            <span slot="prefix" v-if="form.check">
                                【{{ prefixVal }}】
                            </span>
                        </el-input>
                        <!-- <el-checkbox @change="checkChangeFun" class="m_l_24" v-model="form.check">
                            自定义编号
                        </el-checkbox> -->
                    </el-form-item>
                    <el-form-item prop="register_time" class="w47" label="登记时间：">
                        <el-date-picker
                            :disabled="true"
                            class="w100"
                            v-model="form.register_time"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetime"
                            placeholder="选择登记时间"
                        >
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="工程名称：" prop="engineering_name">
                        <el-autocomplete
                            class="w100"
                            :disabled="isView"
                            v-model="form.engineering_name"
                            :fetch-suggestions="querySearchAsyncReject"
                            placeholder="请输入"
                            @select="handleSelectReject"
                        ></el-autocomplete>
                    </el-form-item>
                    <el-form-item class="w47" label="工程编号：">
                        <el-input disabled v-model="form.engineering_number"></el-input>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w94" label="工程地址：" prop="engineering_location">
                        <el-input :disabled="isView" v-model="form.engineering_location">
                            <el-button
                                slot="append"
                                icon="el-icon-location"
                                @click="openMapFun"
                            ></el-button>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="供方单位：" prop="org_name">
                        <el-input :disabled="isView" @click.native="selectSupplierUnit" v-model="form.org_name"></el-input>
                    </el-form-item>
                    <el-form-item class="w47" label="工程类型：" prop="engineering_type">
                        <el-select
                            :disabled="isView"
                            v-model="form.engineering_type"
                            @change="projectChangeFun"
                            class="w100"
                            placeholder="==请选择=="
                        >
                            <el-option
                                v-for="item in projectData"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="相关单体：">
                        <el-input :disabled="isView" v-model="form.monomer_names" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item class="w47" label="标段：">
                        <el-input :disabled="isView" v-model="form.section" placeholder="请输入"></el-input>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="客户名称：" prop="client_name">
                        <!-- <el-input @click.native="clientClickFun" v-model="form.client_contact_name"></el-input> -->
                        <el-autocomplete
                            class="w100"
                            :disabled="isView"
                            v-model="form.client_name"
                            :fetch-suggestions="querySearchAsyncClient"
                            placeholder="请输入"
                            @select="handleSelectClient"
                        ></el-autocomplete>
                    </el-form-item>
                    <el-form-item class="w30" label="">
                        <el-button
                            class="qcc_btn"
                            type="primary"
                            size="mini"
                            @click="goQccFun"
                        >
                            企查查
                        </el-button>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="客户类型：" prop="client_type">
                        <el-select
                            :disabled="isView"
                            class="w100"
                            v-model="form.client_type"
                            placeholder="==请选择=="
                        >
                            <el-option
                                v-for="item in clientData"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <div class="m_l_24 w47">
                        <el-form-item label="统一信用代码/身份证号：">
                            <el-input
                                :disabled="isView"
                                placeholder="请输入"
                                class="w95"
                                v-model="form.client_uscc"
                            ></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="客户联系人：">
                        <el-input :disabled="isView" placeholder="请输入" v-model="form.client_contact_name"></el-input>
                    </el-form-item>
                    <el-form-item class="w47" label="联系电话：">
                        <el-input :disabled="isView" placeholder="请输入" v-model="form.client_contact_phone"></el-input>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="业务员：" prop="salesman_name">
                        <el-input
                            :disabled="isView"
                            type="text"
                            placeholder="请输入"
                            v-model="form.salesman_name"
                        >
                            <i
                                slot="suffix"
                                title="选择业务人员"
                                style="cursor:pointer;"
                                @click="selectUser(1)"
                                class="iconfont icon05qunzufill"
                            ></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item class="w47" label="业务员电话：">
                        <el-input :disabled="isView" placeholder="请输入" v-model="form.salesman_phone"></el-input>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="服务人员：">
                        <el-input :disabled="isView" placeholder="请输入" v-model="form.waiter_name">
                            <i
                                slot="suffix"
                                title="选择服务人员"
                                style="cursor:pointer;"
                                @click="selectUser(2)"
                                class="iconfont icon05qunzufill"
                            ></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item class="w47" label="服务人员电话：">
                        <el-input :disabled="isView" placeholder="请输入" v-model="form.waiter_phone"></el-input>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="预计方量（m³）：">
                        <el-input :disabled="isView" placeholder="请输入" v-model="form.projected_capacity"></el-input>
                    </el-form-item>
                    <el-form-item class="w47" label="运距（㎞）：" prop="haul_distance">
                        <el-input :disabled="isView" placeholder="请输入" v-model="form.haul_distance"></el-input>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="供货站点：" prop="supply_site">
                        <el-select
                            class="w100"
                            :disabled="isView"
                            v-model="form.supply_site"
                            @change="siteChangeFun('supply_site', 'supply_site_name')"
                            placeholder="==请选择=="
                        >
                            <el-option
                                v-for="item in fieldData"
                                :key="item.station_id"
                                :label="item.name"
                                :value="item.station_id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="w47" label="生产站点：" prop="production_station_id">
                        <el-select
                            class="w100"
                            :disabled="isView"
                            v-model="form.production_station_id"
                            @change="siteChangeFun('production_station_id','production_station_name')"
                            placeholder="==请选择=="
                        >
                            <el-option
                                v-for="item in fieldData"
                                :key="item.station_id"
                                :label="item.name"
                                :value="item.station_id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="签约时间：" prop="signing_date">
                        <el-date-picker
                            class="w100"
                            :disabled="isView"
                            v-model="form.signing_date"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择签约时间"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item class="w47" label="预计开工时间：">
                        <el-date-picker
                            class="w100"
                            :disabled="isView"
                            v-model="form.predicted_startwork_time"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择预计开工时间"
                        >
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="施工单位：">
                        <el-input :disabled="isView" placeholder="请输入" v-model="form.construction_enterprise"></el-input>
                    </el-form-item>
                    <el-form-item class="w47" label="统一社会信用代码：">
                        <el-input :disabled="isView" placeholder="请输入" v-model="form.construction_uscc"></el-input>
                    </el-form-item>
                </div>
                <div class="form_item_box">
                    <el-form-item class="w47" label="合同类型：" prop="contract_type">
                        <el-select
                            :disabled="isView"
                            class="w100"
                            v-model="form.contract_type"
                            @change="contractChangeFun"
                            placeholder="==请选择=="
                        >
                            <el-option
                                v-for="item in contractTypeData"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        v-if="form.contract_type === '102-LS1'"
                        class="w47"
                        label="临时期限："
                        prop="Interim_period"
                    >
                        <el-input :disabled="isView" v-model="form.Interim_period"></el-input>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <div class="head_title">
            工地要料人
            <el-button
                class="head_add_btn"
                type="primary"
                size="mini"
                :disabled="isView"
                @click="addUserFun"
            >
                新增
            </el-button>
        </div>
        <div class="form_box get_user_box">
            <div class="get_user" v-for="(item, i) in userData" :key="i">
                <div class="w25">
                    <div class="user_title">
                        要料人姓名
                    </div>
                    <div>
                        <el-input :disabled="isView" v-model="item.want_material_name"></el-input>
                    </div>
                </div>
                <div class="w35 m_l_24">
                    <div class="user_title">
                        要料人电话
                    </div>
                    <div>
                        <el-input :disabled="isView" v-model="item.want_material_phone"></el-input>
                    </div>
                </div>
                <div class="w40 m_l_24">
                    <div class="user_title">
                        要料人身份证号
                    </div>
                    <div>
                        <el-input :disabled="isView" v-model="item.want_material_idcard"></el-input>
                    </div>
                </div>
                <div>
                    <div class="user_title"></div>
                    <div class="m_l_24">
                        <el-button
                            type="primary"
                            size="mini"
                            :disabled="isView"
                            @click="delUserFun(item, i)"
                        >
                            删除
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        code: {
            type: [Array, Object, String, Function],
            defaule: () => {},
        },
    },
    data() {
        return {
            // 表单数据
            form: {
                contract_number: '', // 合同编号
                register_time: '', // 登记时间
                contract_type: '', // 合同类型
                contract_type_name: '', // 合同类型名称
                engineering_name: '', // 工程名称
                engineering_number: '', // 工程编号
                engineering_id: '', // 工程engineering_id
                engineering_location: '', // 工程地址
                x_coordinate_point: '', // 工程坐标
                y_coordinate_point: '', // 工程坐标
                org_code: '', // 供方单位编码
                org_name: '', // 供方单位名称
                engineering_type: '', // 工程类型
                engineering_type_name: '', // 工程类型名称
                monomer_names: '', // 相关单体
                section: '', // 标段
                client_id: '', // 客户名称
                client_name: '', // 客户名称
                client_type: '', // 客户类型
                client_type_name: '', // 客户类型名称
                client_uscc: '', // 统一信用代码/身份证号
                client_contact_id: '', // 客户联系人id
                client_contact_name: '', // 客户联系人
                client_contact_phone: '', // 客户联系人电话
                salesman_name: '', // 业务员
                salesman_phone: '', // 业务员电话
                waiter_name: '', // 服务人员
                waiter_phone: '', // 服务人员电话
                projected_capacity: '', // 预计方量
                haul_distance: '', // 运距
                supply_site: '', // 供货站点
                supply_site_name: '', // 供货站点名称
                production_station_id: '', // 生产站点
                production_station_name: '', // 生产站点名称
                Interim_period: '', // 临时期限
                predicted_startwork_time: '', // 预计开工时间
                signing_date: '', // 签约时间
                construction_enterprise: '', // 施工单位
                construction_uscc: '', // 统一社会信用代码
                check: false, // 自定义编号
            },
            // 校验
            rules: {
                register_time: [
                    { required: true, message: '请选择登记时间', trigger: 'change' },
                ],
                engineering_name: [
                    { required: true, message: '请输入工程名称', trigger: 'change' },
                ],
                engineering_number: [
                    { required: true, message: '请输入工程编号', trigger: 'change' },
                ],
                engineering_location: [
                    { required: true, message: '请输入工程地址', trigger: 'change' },
                ],
                x_coordinate_point: [
                    { required: true, message: '请选择工程坐标', trigger: 'change' },
                ],
                org_name: [
                    { required: true, message: '请选择供方单位', trigger: 'change' },
                ],
                engineering_type: [
                    { required: true, message: '请选择工程类型', trigger: 'change' },
                ],
                client_name: [
                    { required: true, message: '请选择客户', trigger: 'change' },
                ],
                client_type: [
                    { required: true, message: '请选择客户类型', trigger: 'change' },
                ],
                salesman_name: [
                    { required: true, message: '请选择业务员', trigger: 'change' },
                ],
                haul_distance: [
                    { required: true, message: '请输入运距', trigger: 'blur' },
                ],
                supply_site: [
                    { required: true, message: '请选择供货站点', trigger: 'change' },
                ],
                production_station_id: [
                    { required: true, message: '请选择生产站点', trigger: 'change' },
                ],
                Interim_period: [
                    { required: true, message: '请输入临时时间', trigger: 'blur' },
                ],
                signing_date: [
                    { required: true, message: '请选择签约时间', trigger: 'change' },
                ],
                contract_type: [
                    { required: true, message: '请选择合同类型', trigger: 'change' },
                ],
            },
            // 合同类型下拉数据
            contractTypeData: [],
            // 工程类型下拉数据
            projectData: [],
            // 客户类型下拉数据
            clientData: [],
            // 场站下拉数据
            fieldData: [],
            // 要料人数据
            userData: [
                {
                    want_material_name: '', // 要料人姓名
                    want_material_phone: '', // 要料人电话
                    want_material_idcard: '', // 要料人身份证号
                },
            ],
            prefixVal: '',
            isView: false,
        };
    },
    created() {
        // 只能查看
        if (this.code.viewScene === 'view') {
            this.isView = true;
        }
        this.prefixVal = this.$takeTokenParameters('TenantCode');
        this.initSelectData();
        if (this.code.cvid) {
            this.form.cvid = this.code.cvid;
            this.getDetailFun();
        } else {
            this.form.register_time = new Date();
        }
    },
    methods: {
        // 打开地图选择坐标
        openMapFun() {
            this.$toast({
                title: true,
                text: '选择坐标',
                type: 'eject',
                width: '78%',
                height: '93%',
                t_url: 'map/stb_map',
                viewPosition: 'view',
                extr: {
                    x_coordinate_point: this.form.x_coordinate_point,
                    y_coordinate_point: this.form.y_coordinate_point,
                    savaCabck: this.savaCabck,
                },
            });
        },
        savaCabck(val, site) {
            this.form.x_coordinate_point = val[0] + ''; // 工程坐标
            this.form.y_coordinate_point = val[1] + ''; // 工程坐标
            this.form.engineering_location = site; // 地址
        },
        // 获取工程名称下拉数据
        querySearchAsyncReject(queryString, cb) {
            const url = `/interfaceApi/sale/engineering_manager/get_engineering_by_condition?engineering_name=${this.form.engineering_name}`;
            this.$axios.get(url).then(res => {
                const arr = res;
                arr.forEach(item => {
                    item.value = item.engineering_name;
                    item.address = item.engineering_id;
                });
                cb(arr);
            }).catch(error => {
                this.$message.error(error);
            });
        },
        // 获取客户名称下拉数据
        querySearchAsyncClient(queryString, cb) {
            const url = `/interfaceApi/sale/client_manager/get_clients?Keywords=${this.form.client_contact_name}`;
            this.$axios.get(url).then(res => {
                const arr = res;
                arr.forEach(item => {
                    item.value = item.name;
                    item.address = item.id;
                });
                cb(arr);
            }).catch(error => {
                this.$message.error(error);
            });
        },
        // 工程名称组合框选中
        handleSelectReject(item) {
            // this.form.engineering_name = item.engineering_name; // 工程名称
            this.form.engineering_id = item.eiid; // 工程engineering_id
            this.form.engineering_number = item.engineering_number; // 工程编号
            this.form.engineering_location = item.engineering_location; // 工程地址
            this.form.engineering_type = item.engineering_type; // 工程地址
            this.form.engineering_type_name = item.engineering_type_name; // 工程地址
            this.form.x_coordinate_point = item.x_coordinate_point; // 工程坐标
            this.form.y_coordinate_point = item.y_coordinate_point; // 工程坐标
        },
        // 客户组合框选中
        handleSelectClient(item) {
            this.form.client_type = item.nature; // 客户类型id
            this.form.client_type_name = item.nature_name; // 客户类型名称
            this.form.client_id = item.id; // 客户id
            this.form.client_uscc = item.license_code; // 客户身份证号
            this.form.client_contact_name = item.client_contact_name; // 客户联系人
            this.form.client_contact_phone = item.client_contact_phone; // 客户联系人电话
            this.form.client_name = item.name; // 客户name
            this.form.client_id = item.id; // 客户id
        },
        // 站点change事件，处理名称
        siteChangeFun(key, nameKey) {
            this.fieldData.forEach(item => {
                if (this.form[key] === item.station_id) {
                    this.form[nameKey] = item.name;
                }
            });
        },
        // 合同类型change事件，处理名称
        contractChangeFun() {
            this.form.Interim_period = '';
            this.contractTypeData.forEach(item => {
                if (this.form.contract_type === item.code) {
                    this.form.contract_type_name = item.name;
                }
            });
        },
        // 工程类型change事件，处理名称
        projectChangeFun() {
            this.projectData.forEach(item => {
                if (this.form.engineering_type === item.code) {
                    this.form.engineering_type_name = item.name;
                }
            });
        },
        // 初始化下拉框数据
        initSelectData() {
            this.getFieldDataFun();
            this.getClientDataFun('102', 'contractTypeData', 1);
            this.getClientDataFun('002-XZ0', 'clientData', 0);
            this.getClientDataFun('103', 'projectData', 1);
        },
        // 获取客户类型数据
        getClientDataFun(num, key, type) {
            this.$axios.get(`/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=${num}&type=${type}`).then(res => {
                if (res) {
                    this[key] = res;
                } else {
                    this[key] = [];
                }
            }).catch(error => {
                this.$message.error(error);
            });
        },
        // 获取
        getFieldDataFun() {
            this.$axios.get('/interfaceApi/baseinfo/stationmanger/get_station_list').then(res => {
                if (res) {
                    this.fieldData = res;
                }
            }).catch(error => {
                this.$message.error(error);
            });
        },
        // 获取详情信息接口
        getDetailFun() {
            this.$axios.post(`/interfaceApi/sale/contract_manager/base_contract_info/${this.code.cvid}`).then(res => {
                if (res) {
                    this.form = res;
                    this.userData = res.WantMaterialList && res.WantMaterialList[0] ? res.WantMaterialList : [];
                }
            }).catch(error => {
                this.$message.error(error);
            });
        },
        // 选择供方单位
        selectSupplierUnit() {
            this.$toast({
                title: true,
                type: 'eject',
                width: '15rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'settlement_company_list',
                        sureCallBack: this.selectSupplierUnitCallBack,
                    },
                },
            });
        },
        // 选择供方单位回调
        selectSupplierUnitCallBack(data) {
            this.form.org_code = data.org_code;
            this.form.org_name = data.org_name;
            this.$forceUpdate();
        },

        // 选择单体
        selectMonomer() {
            if (this.form.engineering_id === '') {
                this.$message.warning('没有工程信息，请先选择合同。');
                return;
            }
            /** 合同类型 施工方式为：施工单位**/
            // if (this.formData.construction_method === 2) {
            //     this.beforeOpening('order_select_monomer', this.selectMonomerCallback);
            // } else if (this.formData.is_specify_monomer === 0) {
            //     this.openMonomerTablePage('chose_monomer');
            // } else {
            //     this.openMonomerTablePage('chose_monomer_contract');
            // }
            this.openMonomerTablePage('contract_choose_monomer');
        },

        beforeOpening(TableCode, callbackFun) {
            if (this.formData.construction_method === 2) {
                if (this.formData.contract_version === '') {
                    this.$message.warning('没有工程信息，请先选择合同。');
                    return;
                }
                if (['order_select_monomer', 'order_select_want_material'].indexOf(TableCode) > -1) {
                    if ((this.formData.sgdw_id || '') === '') {
                        this.$message.warning('请先选择施工单位');
                        return;
                    }
                }
                this.beforeOpenTablePage(TableCode, callbackFun);
            }
        },
        // 选择单体
        openMonomerTablePage(TableCode) {
            const queryString = `engineering_id=${'f987d85c-cd8f-4a71-adef-616d3b230d5d'}&frameId=frameIdForm201912050947240156`;
            this.$toast({
                title: true,
                type: 'eject',
                width: '70%',
                height: '78%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: TableCode,
                        QueryString: queryString,
                    },
                    selectCallback: this.selectMonomerCallback,
                },
            });
        },

        // 选择单体回调
        async selectMonomerCallback(data) {
            const ids = [];
            const names = [];
            if (data) {
                data.forEach(item => {
                    ids.push(item.monomer_number);
                    names.push(item.monomer_name);
                });
            }
            // this.form.section = ids.join(',');
            this.form.monomer_names = ids.join(',');
        },

        // 选择标段
        sectionOpening() {
            if (this.form.engineering_id === '') {
                this.$message.warning('没有工程信息，请先选择合同。');
                return;
            }
            const queryString = `engineering_id=${'f987d85c-cd8f-4a71-adef-616d3b230d5d'}&frameId=frameIdForm201912050947240156`;
            this.$toast({
                title: true,
                type: 'eject',
                width: '70%',
                height: '78%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'contract_select_bid_section',
                        QueryString: queryString,
                    },
                    selectCallback: this.SelectBidSectionCallback,
                },
            });
        },
        // 选择标段回调
        SelectBidSectionCallback(data) {
            const ids = [];
            const names = [];
            if (data) {
                data.forEach(item => {
                    ids.push(item.bid_section_number);
                    names.push(item.bid_section_name);
                });
            }
            this.form.section = ids.join(',');
        },

        // 选择业务员
        selectUser(tag) {
            this.selectTag = tag;
            this.$toast({
                title: true,
                text: '选择人员',
                type: 'eject',
                width: '10rem',
                height: '80%',
                t_url: 'selectUser/selectUser',
                viewPosition: 'components',
                extr: {
                    sureCallBack: this.selectUserCallBack,
                },
            });
        },
        // 选择业务员-回调
        selectUserCallBack(data) {
            // 业务员
            if (this.selectTag === 1) {
                this.form.salesman_phone = data.Mobile;
                this.form.salesman_name = data.User_Name;
                this.form.salesman_id = data.User_Id;
                // 服务员
            } else if (this.selectTag === 2) {
                this.form.waiter_phone = data.Mobile;
                this.form.waiter_name = data.User_Name;
                this.form.waiter_id = data.User_Id;
            }
        },
        // 新增要料人
        addUserFun() {
            this.userData.push({
                want_material_name: '', // 要料人姓名
                want_material_phone: '', // 要料人电话
                want_material_idcard: '', // 要料人身份证号
            });
        },
        // 删除要料人
        delUserFun(item, i) {
            // 有id需要调用接口删除
            if (item.cwmvid) {
                this.setApiDelFunFun(item, i);
            } else {
                this.userData.splice(i, 1);
                this.$message.success('删除成功');
            }
        },
        // 删除接口
        setApiDelFunFun(item, i) {
            this.$axios.delete(`/interfaceApi/sale/contract_manager/delete_contract_material/${item.cwmvid}`).then(res => {
                this.$message.success('删除成功');
                this.userData.splice(i, 1);
            }).catch(error => {
                this.$message.error(error);
            });
        },
        // 客户名称点击
        clientClickFun() {
            const addTableData = {
                showCheckBox: true,
                requestMethod: 'post',
                headerData: [{
                    title: '用户ID',
                    field: 'User_Id',
                }, {
                    title: '用户名',
                    field: 'User_Name',
                }, {
                    title: '登录名',
                    field: 'Login_Name',
                }, {
                    title: '描述',
                    field: 'Desc',
                }, {
                    title: '创建时间',
                    field: 'Create_Time',
                }],
                requestUrl: 'basicplatform/tabledata?frameId=frameId0',
                requestParameter: {
                    page: 0,
                    limit: 15,
                },
            };
            this.$toast({
                title: true,
                text: '添加客户',
                type: 'eject',
                width: '15rem',
                height: '75%',
                viewPosition: 'components',
                t_url: 'table/addTable',
                extr: {
                    tips: '添加客户',
                    tableData: addTableData,
                    sureCallBack: this.addClientCallBack,
                },
            });
        },
        addClientCallBack(item) {
            console.log(item, 'item');
        },
        // 自定义编号change
        checkChangeFun() {
            if (!this.form.check) {
                this.form.contract_number = '';
            }
        },
        goQccFun() {
            window.open(`https://www.qcc.com/web/search?key=${this.form.client_contact_name}`, '_blank');
        },
    },
};
</script>

<style scoped lang="stylus">
    .main_box{
        height calc(100% - 0.47rem)
        padding-bottom 0.14rem
        overflow: hidden;
        overflow-y: auto;
        background: #fff;
        .head_title{
            height: 0.55rem;
            line-height: 0.55rem;
            font-size: 0.18rem;
            padding-left: 0.24rem;
            background: color_4;
            position: relative;
            .head_add_btn{
                position: absolute;
                right: 0.24rem;
                top: 0.14rem;
            }
        }
        .form_box {
            padding: 0.1rem 0.2rem;
        }
        .get_user_box{
            .get_user{
                display: flex;
                .user_title{
                    height: 0.4rem;
                    line-height: 0.4rem;
                    font-size 0.16rem
                }
            }
        }
        .form_item_box{
            display: flex;
            >>>.el-form-item{
                margin-bottom: 16px;
                .el-form-item__label{
                    font-size 0.14rem
                    line-height 0.36rem
                }
                .el-form-item__content{
                    height 0.36rem
                    line-height 0.36rem
                    .el-form-item__error{
                        position: initial;
                        font-size 0.12rem
                        padding 2px 0
                    }
                }
            }
            .p_l_inp{
                .el-input__inner {
                    padding-left: 0.45rem !important;
                }
            }
            .qcc_btn{
                margin-left: -1.3rem;
            }
            .el-button{
                font-size 0.14rem
                padding 0 0.15rem
                line-height 0.34rem
            }
        }
        .w25{
            width: 25%;
        }
        .w35{
            width: 35%;
        }
        .w40{
            width: 40%;
        }
        .w47{
            width: 47%;
        }
        .w50{
            width: 50%;
        }
        .w60{
            width: 60%;
        }
        .w70{
            width: 70%;
        }
        .w80{
            width: 80%;
        }
        .w90{
            width: 90%;
        }
        .w94{
            width: 94%;
        }
        .w95{
            width: 95%;
        }
        .w100{
            width: 100%;
        }
        .m_l_24{
            margin-left: 0.24rem;
        }
        .iconfont {
            font-size: 0.2rem;
        }
    }
</style>